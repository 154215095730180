

 .RegistrationPicture {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255); 
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }

@media (max-device-width: 960px) {
  .RegistrationPicture {
    top: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;
  }
}

@media (min-device-width: 961px) {
  .RegistrationPicture {
    top: 10%;
    left: 15%;
    right: 15%;
    bottom: 10%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;
  }
}