html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-y: scroll;
  color: #005587 !important; /* ovBlue */
  background-color: #E6E6E6 !important; /* ovGreyLight */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *:focus {
      outline: none !important;
  }
}

button:focus {
  outline: none !important;
}
