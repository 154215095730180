@font-face {
  font-family: 'Molde';
  src: local('Molde'), url(/static/media/Molde-Condensed-Semibold.ce12ef48.otf) format('opentype');
}

@font-face {
    font-family: 'marbachlig';
    src: url(/static/media/marbachlig-webfont.b3910850.woff2) format('woff2'),
         url(/static/media/marbachlig-webfont.9cf723f8.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
.content-lightbox {}

.image_1swebtw-o_O-imageLoaded_zgbg08 {
    image-orientation: from-image;
}
.content-lightbox {}
.image_1swebtw-o_O-imageLoaded_zgbg08 {
  image-orientation: from-image;
}
.content-lightbox {}
.image_1swebtw-o_O-imageLoaded_zgbg08 {
  image-orientation: from-image;
}


 .RegistrationPicture {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255); 
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }

@media (max-device-width: 960px) {
  .RegistrationPicture {
    top: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;
  }
}

@media (min-device-width: 961px) {
  .RegistrationPicture {
    top: 10%;
    left: 15%;
    right: 15%;
    bottom: 10%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;
  }
}
html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-y: scroll;
  color: #005587 !important; /* ovBlue */
  background-color: #E6E6E6 !important; /* ovGreyLight */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *:focus {
      outline: none !important;
  }
}

button:focus {
  outline: none !important;
}

