@font-face {
  font-family: 'Molde';
  src: local('Molde'), url(Molde-Condensed-Semibold.otf) format('opentype');
}

@font-face {
    font-family: 'marbachlig';
    src: url('marbachlig-webfont.woff2') format('woff2'),
         url('marbachlig-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}